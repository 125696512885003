
  import { defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'ire-literal-filter',
    emit: ['ire-literal-type-selected', 'reset'],
    components: {
      Multiselect,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref<any>({
        areaId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('ire-literal-type-selected', selectedItems);
      };
      const onReset = () => {
        selectedItems.value = {
          areaId: '',
        };
        emit('reset', selectedItems.value);
      };

      await store.dispatch(Actions.GET_ALL_IRE_COMMON_AREAS, { type: 3 });

      reinitializeComponents();

      return {
        areas: computed(() => store.getters.ireCommonAreasAllList),
        onSubmit,
        onReset,
        translate,
        selectedItems,
      };
    },
  });
